import '@less/main.less';
import '@less/media.less';
// import 'jquery-ui/';
// import 'jquery-migrate/dist/jquery-migrate.min';
import 'slick-slider';
import '@fancyapps/fancybox/dist/jquery.fancybox';

/* 
-------------------------------------------
| WP IMPORTES AS TEMPLATE
-------------------------------------------
*/

// import '@wp/wp-content/plugins/contact-form-7/includes/js/scripts.js_ver=5.2';
import '@wp/wp-content/themes/kava/assets/js/theme-script.js';
// import '@wp/wp-includes/js/wp-embed.min.js_ver=5.4.2';
import '@wp/wp-includes/js/hoverIntent.min.js_ver=1.8.1';
import '@wp/wp-includes/js/imagesloaded.min.js_ver=3.2.0';
import '@wp/wp-content/plugins/jet-elements/assets/js/lib/slider-pro/jquery.sliderPro.min.js_ver=1.3.0';
import '@wp/wp-content/plugins/jet-elements/assets/js/lib/html2canvas/html2canvas.min.js_ver=1.0.0-rc.5';
import '@wp/wp-content/plugins/jet-elements/assets/js/lib/oridomi/oridomi.js_ver=1.10.0';
import '@wp/wp-content/plugins/jet-elements/assets/js/lib/peeljs/peeljs.js_ver=1.0.0';
import '@wp/wp-content/plugins/elementor/assets/lib/jquery-numerator/jquery-numerator.min.js_ver=0.2.1';
import '@wp/wp-content/plugins/jet-blocks/assets/js/lib/jsticky/jquery.jsticky.min.js_ver=1.1.0';
import '@wp/wp-content/plugins/elementor/assets/js/frontend-modules.min.js_ver=2.9.14';
// import '@wp/wp-includes/js/jquery/ui/position.min.js_ver=1.11.4';
// import '@wp/wp-content/plugins/elementor/assets/lib/dialog/dialog.min.js_ver=4.7.6';
import '@wp/wp-content/plugins/elementor/assets/lib/waypoints/waypoints.min.js_ver=4.0.2';
import '@wp/wp-content/plugins/elementor/assets/lib/swiper/swiper.min.js_ver=5.3.6';
// import '@wp/wp-content/plugins/elementor/assets/lib/share-link/share-link.min.js_ver=2.9.14';
import '@wp/wp-content/plugins/elementor/assets/js/frontend.min.js_ver=2.9.14';
import '@wp/wp-content/plugins/jet-blocks/assets/js/jet-blocks.min.js_ver=1.2.4';
import '@wp/wp-content/plugins/jet-elements/assets/js/jet-elements.min.js_ver=2.3.0';
import '@wp/wp-content/plugins/jet-tabs/assets/js/jet-tabs-frontend.min.js_ver=2.1.7';
import '@wp/wp-content/plugins/jet-tricks/assets/js/jet-tricks-frontend.js_ver=1.2.10';
import '@wp/wp-content/plugins/jet-blog/assets/js/jet-blog.min.js_ver=2.2.9';

/* 
-------------------------------------------
| END
-------------------------------------------
*/

function createMap(height, appendedBlock) {
   $(appendedBlock).append(
      `<script type="text/javascript" charset="utf-8" src="https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3A7e43300c5fad5d0b6a175488820fa6c19be98ae6bdef671fd1891942bbc60ba4&amp;width=100%&amp;height=${height}&amp;lang=ru_RU&amp;scroll=true"></script>`
   );
}

const works = $('.custom-element-works .list-works');
const leftWorks = $('.custom-element-works .arrows-block .left-arrow');
const rightWorks = $('.custom-element-works .arrows-block .right-arrow');
const formReivew = $('.custom-element-send-review .content-block > form');
const anchor = $('.anchor');
const sliderWork = $('.custom-element .works-images');

let screenWidth = window.innerWidth;

works.slick({
   infinite: true,
   slidesToShow: 3,
   slidesToScroll: 3,
   autoplay: true,
   autoplaySpeed: 5000,
   arrows: false,
   responsive: [
      {
         breakpoint: 991,
         settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            draggable: true,
         },
      },
   ],
});

leftWorks.on('click', () => {
   works.slick('slickPrev');
});

rightWorks.on('click', () => {
   works.slick('slickNext');
});

formReivew.find('.input-field').each(function() {
   if (
      $(this)
         .children('input,textarea')
         .attr('required')
   ) {
      $(this)
         .children('label')
         .append('<span class="alert">*</span>');
   }
});

anchor.on('click', function(e) {
   e.preventDefault();
   let id = $(this).attr('href');

   $('html, body')
      .stop()
      .animate(
         {
            scrollTop: $(id).offset().top,
         },
         1000
      );
});

sliderWork.slick({
   infinite: true,
   slidesToShow: 4,
   slidesToScroll: 1,
   autoplay: true,
   autoplaySpeed: 5000,
   arrows: false,
   responsive: [
      {
         breakpoint: 991,
         settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            draggable: true,
         },
      },
   ],
});

$(function() {
   $('.page-preloader-cover-new').fadeOut(400);
   $('body').css({
      overflow: '',
   });
   setTimeout(() => {
      $('.page-preloader-cover-new').remove();
   }, 800);
});

window.onload = () => {
   const imgServis = {
      height: $('.elementor-widget-img__servis').height(),
      appendedBlock: $('.elementor-widget-google_maps .elementor-custom-embed'),
   };
   while (imgServis.height < 100) {
      imgServis.height = $('.elementor-widget-img__servis').height();
   }
   createMap(imgServis.height, imgServis.appendedBlock);
};
